
import {
  defineComponent,
  computed,
  ref,
  onUpdated,
  onMounted,
  onUnmounted
} from 'vue';
import { lostelkUrl } from '../../global';
import router from '../../router';
import Likes from '../../components/cardFun/Likes.vue';
import DownloadFile from '../../components/cardFun/DownloadFile.vue';
import Comments from '../../components/comment/Comments.vue';
import DeleteCard from '../../components/cardFun/DeleteCard.vue';
import store from '../../store';
import copy from 'copy-to-clipboard';
import createTooltip from '@/components/globalFun/createTooltip';
export default defineComponent({
  name: 'HomeCardDetails',
  components: {
    Likes,
    DownloadFile,
    Comments,
    DeleteCard
  },
  props: {
    // 路由传来的当前帖子的ID
    id: {
      type: String,
      required: true
    },
    tagName: {
      type: String,
      required: false
    }
  },
  setup(props) {
    // 是否为单个卡片
    const singleCard = ref(false);
    // 获取当前用户ID
    const userId = computed(() => store.state.user.id);
    // 获取当前帖子的ID
    const postId = computed(() => Number(props.id));

    // 动态获取图像文件元信息
    const fileMetadata = ref();

    /**
     * 获取单个文章数据
     */
    const showCard = ref(false);
    const postData = ref();
    const cardList = computed(() => store.state.cardList);
    const cardIndex = computed(() =>
      cardList.value.findIndex(item => item.id === postId.value)
    );

    // 如果当前帖子存在于cardList数组中,进入if 否则 进入else
    if (cardIndex.value !== -1) {
      singleCard.value = false;
      // 帖子存在 开放组件dom
      showCard.value = true;
      // 获取帖子内容
      postData.value = cardList.value[cardIndex.value];
      //请求获取图像文件元信息(不要处理为同步代码,异步操作用于触发onUpdated事件)
      store.dispatch('getFileMetadata', postData.value.file.id).then(data => {
        fileMetadata.value = data;
      });
    } else {
      singleCard.value = true;
      // 获取单个帖子
      store.dispatch('getCard', postId.value).then(data => {
        if (data) {
          // 获取到帖子 开放组件dom
          showCard.value = true;
          // 获取帖子内容
          postData.value = data;
          //请求获取图像文件元信息(不要处理为同步代码,异步操作用于触发onUpdated事件)
          store.dispatch('getFileMetadata', data.file.id).then(data => {
            fileMetadata.value = data;
          });
        }
      });
    }

    /**
     * 修改单个卡片点赞状态
     */
    const singleCardReviseLike = (state: number) => {
      postData.value.liked = state;
    };

    /**
     * 图片放大缩小
     */
    const zoom = ref(true);
    const zoomInAndOut = () => {
      zoom.value = !zoom.value;
    };

    /**
     * 评论按钮
     *
     */
    const showCommentsCut = computed(() => store.state.showCommentsCut);
    const showComments = () => {
      if (showCommentsCut.value) {
        store.commit('showCommentsCut', false);
      } else {
        store.commit('showCommentsCut', true);
      }
    };

    /**
     * 编辑
     */
    const editCard = async () => {
      // 将body恢复为可以滚动
      document.body.style.overflow = 'auto';

      // 存储当前url
      store.commit('uploadAfterToUrl', `/Card/${postId.value}`);
      // 定义当前页面别名,并存储
      store.commit('fromWhichPage', 'home');

      await router.push(`/EditCard/${postId.value}`);
    };

    /**
     * 切换文章
     */
    const rightCutDom = ref();
    const leftCutDom = ref();

    onUpdated(() => {
      /**
       * 如果当前帖子存在于cardList数组中,将左右切换按钮全部禁用
       * 如果当前帖子于cardList数组的第一个元素,将左切换按钮全部禁用
       * 如果当前帖子于cardList数组的最后个元素,将右切换按钮全部禁用
       * 条件都不成立,删除左右切换按钮禁用样式
       */
      if (cardIndex.value === -1) {
        leftCutDom.value.classList.add('noClick');
        rightCutDom.value.classList.add('noClick');
      } else if (cardIndex.value === 0) {
        leftCutDom.value.classList.add('noClick');
      } else if (cardList.value.length - 1 <= cardIndex.value) {
        rightCutDom.value.classList.add('noClick');
      } else {
        leftCutDom.value.classList.remove('noClick');
        rightCutDom.value.classList.remove('noClick');
      }
    });

    const rightCut = async () => {
      // 评论收起
      store.commit('showCommentsCut', false);
      // 图像恢复小图
      zoom.value = true;
      // 如果当前详情的下标不是最后一个则进入if 否则 右按钮添加禁止点击样式
      if (cardList.value.length - 1 > cardIndex.value) {
        // 获得下一张详情页的id
        const rightCutId = cardList.value[cardIndex.value + 1].id;
        // 获得下一张详情页的内容
        postData.value = cardList.value[cardIndex.value + 1];
        //获取图像文件元信息
        const Metadata = await store.dispatch(
          'getFileMetadata',
          postData.value.file.id
        );
        fileMetadata.value = Metadata;

        // 跳转URL
        await router.push(`/card/${rightCutId}`);
        // 清除禁止点击样式
        leftCutDom.value.classList.remove('noClick');
      } else {
        // 添加右按钮按钮禁止点击样式
        rightCutDom.value.classList.add('noClick');
      }
    };

    const leftCut = async () => {
      // 评论收起
      store.commit('showCommentsCut', false);
      // 图像恢复小图
      zoom.value = true;
      // 如果当前详情的下标不是第一个则进入if 否则 左按钮添加禁止点击样式
      if (cardIndex.value > 0) {
        // 获得上一张详情页的id
        const leftCutId = cardList.value[cardIndex.value - 1].id;
        // 获得上一张详情页的内容
        postData.value = cardList.value[cardIndex.value - 1];
        //获取图像文件元信息
        const Metadata = await store.dispatch(
          'getFileMetadata',
          postData.value.file.id
        );
        fileMetadata.value = Metadata;
        // 跳转URL
        await router.push(`/card/${leftCutId}`);
        // 清除禁止点击样式
        rightCutDom.value.classList.remove('noClick');
      } else {
        // 添加左按钮禁止点击样式
        leftCutDom.value.classList.add('noClick');
      }
    };

    /**
     * 复制颜色
     */
    const copyColor = (color: string) => {
      copy(color);
      createTooltip('Copy color number succeeded', 'success', 3000);
    };

    /**
     * 关闭按钮
     */
    const close = () => {
      // 将body恢复为可以滚动
      document.body.style.overflow = 'auto';

      router.push('/');
    };

    /**
     * 获取相关标签数据 并跳转
     */
    const RelatedTagData = async (tagName: string) => {
      // 将body恢复为可以滚动
      document.body.style.overflow = 'auto';
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      // close();

      router.push(`/search/tag/${tagName}`);
    };

    /**
     * 进入用户页
     */
    const toUserPage = (userId: number) => {
      router.push(`/@${userId}`);
      // 将body恢复为可以滚动
      document.body.style.overflow = 'auto';
    };

    onMounted(() => {
      document.body.style.overflow = 'hidden';
    });

    onUnmounted(() => {
      document.body.style.overflow = 'auto';
    });

    return {
      userId,
      showCard,
      lostelkUrl,
      close,
      postId,
      postData,
      rightCut,
      rightCutDom,
      leftCut,
      leftCutDom,
      zoom,
      zoomInAndOut,
      fileMetadata,
      RelatedTagData,
      showComments,
      showCommentsCut,
      editCard,
      toUserPage,
      singleCardReviseLike,
      singleCard,
      copyColor
    };
  }
});
